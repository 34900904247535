import { Duration } from "../Duration";

export const PlayerDuration = ({ duration, played }) => {
    return (
        <div className="player-status">
            <div className="player-status--progress">
                <Duration seconds={duration * played} />
                <Duration seconds={duration * (1 - played)} />
            </div>
        </div>
    );
};
