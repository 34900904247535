export const matchingTarget = (target, selector) => {
    if (target.matches(selector)) {
        return target;
    }

    if (target.parentElement) {
        return matchingTarget(target.parentElement, selector);
    }

    return null;
}

export const targetedEventListener = (selector, callback) => {
    return (event) => {
        if (matchingTarget(event.target, selector)) {
            callback(event);
        }
    }
}
