import { useEffect, useState } from "react"

import { targetedEventListener } from "../../lib/targetedEventListener";

const TRACK_SELECTOR = '[data-track]';
const TRIGGER_SELECTOR = '[data-track-play]';

export const usePlaylist = (onPlay) => {
    const [ current, setCurrent ] = useState(null);
    const [ tracks, setTracks ] = useState([]);

    const exists = (tracks, id) => typeof tracks[id] !== 'undefined';

    const play = (tracks, id, autoplay = true) => {
        if (!exists(tracks, id)) {
            return setCurrent(null);
        }

        const track = tracks[id];
        const data = JSON.parse(track);

        setCurrent(id);
        onPlay(data, autoplay);
    }

    const loadTracks = (element, autoplay = true) => {
        const siblings = element.parentElement.querySelectorAll(TRACK_SELECTOR);

        const index = [...siblings].indexOf(element);
        const tracks = [...siblings].map((element) => element.dataset.track);

        setTracks(tracks);
        play(tracks, index, autoplay);
    };

    useEffect(() => {
        if (window.location.hash) {
            const currentTarget = document.querySelector(window.location.hash);

            if (currentTarget?.dataset.track) {
                loadTracks(currentTarget, false);
            }
        }

        const onClick = targetedEventListener(TRIGGER_SELECTOR, (event) => {
            const trackElement = event.target.closest(TRACK_SELECTOR);

            if (trackElement) {
                loadTracks(trackElement);
            }
        });

        document.addEventListener('click', onClick);

        return () => document.removeEventListener('click', onClick);
    }, []);

    return {
        current,
        previous: exists(tracks, current - 1) ? current - 1 : false,
        next: exists(tracks, current + 1) ? current + 1 : false,
        play: (id) => play(tracks, id),
        stop: () => setCurrent(null),
    };
}
