import { useEffect, useRef } from "react";
import { PlayerDuration } from "../PlayerDuration";

export const Progressbar = ({change, mouseDown, mouseUp, played, duration}) => {
    const progressbar = useRef();

    /** CSS Helper for progress styling */
    useEffect(()=>{
        progressbar.current.style.setProperty('--min', progressbar.current.min === '' ? '0' : progressbar.current.min);
        progressbar.current.style.setProperty('--max', progressbar.current.max === '' ? '100' : progressbar.current.max);

        progressbar.current.addEventListener('input', (e)=>{
            e.target.style.setProperty('--value', e.target.value);
        });
    },[])

    useEffect(()=>{
        progressbar.current.style.setProperty('--value', played);
    },[played])

    return (
        <>
            <input
                ref={progressbar}
                type='range' min={0} max={0.999999} step='any'
                className="styled-slider slider-progress"
                value={played}
                onMouseDown={mouseDown}
                onTouchStart={mouseDown}
                onChange={change}
                onMouseUp={mouseUp}
                onTouchEnd={mouseUp}
            />
            <PlayerDuration played={played} duration={duration} />
        </>
    );
}
