import { targetedEventListener } from "./lib/targetedEventListener";
import { initDynamicLinks } from "./dynamic";

import "./app";

initDynamicLinks('#primary');

/**
 * Language dropdown listener
 */
document.querySelectorAll('.language-navigation--dropdown').forEach((element) => {
    const toggle = element.querySelector('.language-navigation__toggle');

    toggle.addEventListener('click', () => {
        element.classList.toggle('language-navigation--open');
    });
});

/**
 * Language dropdown overlay close listener
 */
document.addEventListener('click', targetedEventListener('.language-navigation__overlay', (event) => {
    event.target.parentElement.classList.remove('language-navigation--open');
}));

/**
 * Map overlay close listener
 */
document.addEventListener('click', targetedEventListener('.map-overlay button', () => {
    document.querySelector('.map-overlay').classList.add('hidden');
}));

/**
 * Map overlay link listeners
 */
document.addEventListener('click', targetedEventListener('a.meta-location', (event) => {
    event.preventDefault();

    const overlay = document.querySelector('.map-overlay');
    const overlayImage = overlay.querySelector('img');

    overlay.classList.add('hidden');
    overlayImage.setAttribute('src', event.target.getAttribute('href'));
    overlay.classList.remove('hidden');
}));
