import React from "react";
import ReactDOM from "react-dom/client";

import { Player } from "./components/Player";

const app = ReactDOM.createRoot(document.getElementById('audioguide-player'));

app.render(
    React.createElement(Player)
);
