
export const PrevIcon = () => {
    return (
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.57348 15.8742L27.5144 29.1746C28.1809 29.5449 29 29.063 29 28.3005L29 1.69951C29 0.937031 28.1809 0.455061 27.5144 0.825357L3.57348 14.1258C2.88768 14.5068 2.88767 15.4931 3.57348 15.8742Z" fill="white"/>
            <path d="M4 29C4 29.5523 3.55228 30 3 30L1 30C0.447715 30 3.91405e-08 29.5523 8.74228e-08 29L2.53526e-06 1C2.58354e-06 0.447715 0.447718 -3.10551e-07 1 -2.62268e-07L3 -8.74228e-08C3.55229 -3.91405e-08 4 0.447716 4 1L4 29Z" fill="white"/>
        </svg>
    );
}

export const NextIcon = () => {
    return (
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.4265 14.1258L1.48564 0.825357C0.819112 0.455062 0 0.937029 0 1.69951V28.3005C0 29.063 0.819111 29.5449 1.48564 29.1746L25.4265 15.8742C26.1123 15.4932 26.1123 14.5068 25.4265 14.1258Z" fill="white"/>
            <path d="M25 1C25 0.447716 25.4477 0 26 0H28C28.5523 0 29 0.447715 29 1V29C29 29.5523 28.5523 30 28 30H26C25.4477 30 25 29.5523 25 29V1Z" fill="white"/>
        </svg>
    );
}

export const PlayIcon = () => {
    return (
        <svg width="33" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path transform="translate(5,0)" d="M22.6432 14.152L1.53 0.956249C0.863951 0.539969 0 1.01881 0 1.80425V28.1958C0 28.9812 0.86395 29.46 1.53 29.0438L22.6432 15.848C23.2699 15.4563 23.2699 14.5437 22.6432 14.152Z" fill="black"/>
        </svg>
    );
}

export const PauseIcon = () => {
    return (
        <svg width="24" height="30" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.177734" y="2" width="10" height="36" rx="1" fill="black"/>
            <rect x="22.1777" y="2" width="10" height="36" rx="1" fill="black"/>
        </svg>
    );
}

export const CloseIcon = () => {
    return (
        <svg className="icon-" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="24.5" fill="#0000004C"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.5366 15L35 16.4634L26.4634 25.0002L35 33.5366L33.5366 35L25.0002 26.4634L16.4634 35L15 33.5366L23.537 25.0002L15 16.4634L16.4634 15L25.0002 23.537L33.5366 15Z" fill="white"/>
        </svg>
    );
}

export const FullscreenIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="24.5" fill="#0000004C"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22.879 26.0332L16.5385 32.3738V28.0769H15V35H21.9231V33.4615L17.6254 33.4623L23.9668 27.121L22.879 26.0332ZM35 15H28.0769V16.5385L32.3746 16.5377L26.0332 22.879L27.121 23.9668L33.4615 17.6262V21.9231H35V15Z" fill="white"/>
        </svg>
    );
}
