import { useEffect, useRef} from "react";
import ReactPlayer from "react-player";

import { PlayerControls, CloseButton, FullscreenButton } from "../PlayerControls";

import { supportsFullscreen } from "../../../lib/fullscreen";
import { usePlayerControls } from "../../hooks/usePlayerControls";
import { usePlaylist } from "../../hooks/usePlaylist";

export const Player = () => {
    const playerRef = useRef();
    const playerWrapperRef = useRef();

    const { load, player, handlers } = usePlayerControls(playerRef, playerWrapperRef);

    const playlist = usePlaylist((track, autoplay = true) => {
        load(track.files, track.title, track.permalink, autoplay);
    });

    const handleResizeEvent = () => {
        const height = playerWrapperRef.current.clientHeight + 'px';
        document.documentElement.style.setProperty('--player-height', height);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResizeEvent);
        handleResizeEvent();
    }, []);

    useEffect(() => {
        handleResizeEvent();
    }, [player.loaded]);

    const showFullscreenToggle = playerRef.current && supportsFullscreen(playerRef.current.getInternalPlayer());

    return (
        <div className={`player-wrapper container ${player.is_loaded ? 'loaded':'not-loaded'}`} ref={ playerWrapperRef }>
            { player.is_loaded &&
                <ReactPlayer
                    ref={playerRef}
                    className="react-player"
                    width="100%"
                    height="56.25%"
                    url={player.url}
                    playing={player.playing}
                    controls={false}
                    loop={player.loop}
                    playbackRate={player.playbackRate}
                    volume={player.volume}
                    muted={player.muted}
                    config={ {attributes: { playsInline: true, muted: player.muted}} }
                    progressInterval={500}
                    onReady={() => {
                        if (player.playing) {
                            handlers.play();
                        }
                        handleResizeEvent();
                    }}
                    onStart={() => console.log("onStart")}
                    onPlay={handlers.play}
                    onPause={handlers.pause}
                    onBuffer={() => console.log("onBuffer")}
                    onSeek={(e) => console.log("onSeek", e)}
                    onEnded={handlers.ended}
                    onError={(e) => console.log("onError", e)}
                    onProgress={handlers.progress}
                    onDuration={handlers.duration}
                />
            }
            { player.is_loaded && player.hasVideo && (
                <>
                    <CloseButton
                        onClick={() => {
                            load(null);
                            playlist.stop();
                        }}
                    />
                    { showFullscreenToggle ? (
                        <FullscreenButton
                            onClick={() => {
                                handlers.fullscreenToggle();
                            }}
                        />
                    ) : null }
                </>
            ) }
            <PlayerControls player={player} handlers={handlers} playlist={playlist} />
        </div>
    );
};

export default Player;
