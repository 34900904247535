import { CloseIcon, FullscreenIcon, NextIcon, PauseIcon, PlayIcon, PrevIcon } from "../Icons";
import { Progressbar } from "../ProgressBar";

export const PlayerControls = ({player, handlers, playlist}) => {
    return (
        <div className="player-controls">
            <div className="player-title">
                {player.permalink ? (
                    <a href={player.permalink} data-dynamic-target="#primary">{player.title}</a>
                ) : (
                    player.title
                )}
            </div>

            <Progressbar
                mouseDown={handlers.seekMouseDown}
                mouseUp={handlers.seekMouseUp}
                change={handlers.seekChange}
                played={player.played}
                duration={player.duration}
            />

            <div className="buttons">
                <button
                    type="button"
                    disabled={playlist.previous===false}
                    className="player-control player-btn-prev"
                    onClick={ (e) => playlist.play(playlist.previous) }
                >
                    <PrevIcon />
                </button>

                <button
                    type="button"
                    className="player-control player-btn-play"
                    disabled={player.url === null}
                    onClick={handlers.playPause}
                >
                    { player.playing ? (
                        <PauseIcon />
                    ) : (
                        <PlayIcon />
                    ) }
                </button>

                <button
                    type="button"
                    className="player-control player-btn-next"
                    disabled={playlist.next === false}
                    onClick={ (e) =>  playlist.play(playlist.next) }
                >
                    <NextIcon />
                </button>
            </div>
        </div>
    );
}

export const CloseButton = ({onClick, disabled}) => {
    return (
        <button
            className="player-control player-btn-close"
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <CloseIcon />
        </button>
    );
}

export const FullscreenButton = ({onClick, disabled}) => {
    return (
        <button
            className="player-control player-btn-fullscreen"
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <FullscreenIcon />
        </button>
    );
}
