export function supportsFullscreen(element) {
    if (document.fullscreenEnabled === false) {
        return false;
    }

    return (
        element.requestFullscreen ||
        element.webkitEnterFullscreen ||
        element.webkitRequestFullscreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullscreen
    );
}

export function requestFullscreen(element) {
    let promise = null;

    if (element.requestFullscreen) {
        promise = element.requestFullscreen();
    } else if (element.webkitEnterFullscreen) {
        promise = element.webkitEnterFullscreen();
    } else if (element.webkitRequestFullscreen) {
        promise = element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
        promise = element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        promise = element.msRequestFullscreen();
    } else {
        return Promise.reject(Error("Fullscreen API unavailable"));
    }

    return promise instanceof Promise ? promise : Promise.resolve();
}

export function exitFullscreen() {
    if (document.exitFullscreen) {
        return document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
        return document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        return document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        return document.msExitFullscreen();
    } else {
        return Promise.reject(false);
    }
};
