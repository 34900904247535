import { useReducer } from "react";

import { exitFullscreen, requestFullscreen } from "../../lib/fullscreen";

export const usePlayerControls = (playerRef, playerWrapperRef) => {
    const [ playerState, setPlayerState ] = useReducer(
        (current, update) => ({
            ...current,
            ...update
        }),
        {
            url: null,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 1.0,
            muted: false,
            played: 0,
            loaded: 0,
            buffering: false,
            duration: 0,
            hasVideo: false,
            playbackRate: 1.0,
            loop: false,
            is_loaded: false,
            loadedSeconds: 0,
            playedSeconds: 0,
            title: '',
            permalink: '',
        }
    );

    const load = (url, title = '', permalink = '', autoplay = true) => {
        const hasVideo = isVideo(url);
        const is_loaded = url ? true : false;

        if ((!hasVideo || !is_loaded) && document.fullscreenElement ) {
            handleCloseFullscreen();
        }

        setPlayerState({
            url,
            playing: autoplay,
            played: 0,
            loaded: 0,
            seeking: false,
            is_loaded,
            hasVideo,
            title,
            permalink,
        });
    }

    const isVideo = (url) => {
        let video = false;

        if (!url) {
            return false;
        }

        if ( typeof url === 'string' ) {
            const extension = url.substring(url.lastIndexOf('.') + 1, url.length).toLowerCase();
            video = ['mp4', 'ogg', 'webm'].includes(extension);
        } else {
            // TODO: HANDLE URL IS OBJECT
        }

        return video;
    }

    const handlePlayPause = () => {
        setPlayerState({ playing: !playerState.playing })
    }

    const handleStop = () => {
        setPlayerState({ url: null, playing: false })
    }

    const handleToggleMuted = () => {
        setPlayerState({ muted: !playerState.muted })
    }

    const handlePlay = () => {
        setPlayerState({ playing: true })
    }

    const handlePause = () => {
        setPlayerState({ playing: false });
    };

    const handleSeekMouseDown = (e) => {
        setPlayerState({ seeking: true })
    }

    const handleSeekChange = (e) => {
        setPlayerState({ played: parseFloat(e.target.value) });
    }

    const handleSeekMouseUp = (e) => {
        setPlayerState({ seeking: false })
        playerRef.current.seekTo(parseFloat(e.target.value));
    };

    const handleProgress = (newState) => {
        // We only want to update time slider if we are not currently seeking
        if (!playerState.seeking) {
            setPlayerState(newState);
        }
    };

    const handleEnded = () => {
        setPlayerState({ playing: playerState.loop })
    };

    const handleDuration = (duration) => {
        setPlayerState({ duration })
    };

    const handleOpenFullscreen = () => {
        playerRef.current && requestFullscreen(playerRef.current.getInternalPlayer());
    };

    const handleCloseFullscreen = () => {
        exitFullscreen();
    };

    const handleToggleFullscreen = () => {
        if (document.fullscreenElement) {
            exitFullscreen();
        } else {
            playerRef.current && requestFullscreen(playerRef.current.getInternalPlayer());
        }
    };

    const handleBuffering = () => {
        setPlayerState({ buffering: true });
    }

    return {
        load,
        player: {
            url: playerState.url,
            playing: playerState.playing,
            controls: playerState.controls,
            volume: playerState.volume,
            muted: playerState.muted,
            played: playerState.played,
            loaded: playerState.loaded,
            is_loaded: playerState.is_loaded,
            duration: playerState.duration,
            playbackRate: playerState.playbackRate,
            hasVideo: playerState.hasVideo,
            title: playerState.title,
            permalink: playerState.permalink,
        },
        handlers: {
            play: handlePlay,
            pause: handlePause,
            playPause: handlePlayPause,
            stop: handleStop,
            ended: handleEnded,
            muteToggle: handleToggleMuted,
            seekMouseDown: handleSeekMouseDown,
            seekMouseUp: handleSeekMouseUp,
            seekChange: handleSeekChange,
            duration: handleDuration,
            fullscreenOpen: handleOpenFullscreen,
            fullscreenClose: handleCloseFullscreen,
            fullscreenToggle: handleToggleFullscreen,
            progress: handleProgress
        }
    }
}
